<style>
</style>
<template>
        <v-container>
          <v-row align="center" justify="center">
              <v-col cols="12" sm="12" md="12" lg="12" v-if="!item">
                <Loader />
             </v-col>
              <v-col cols="12" sm="12" md="12" lg="12"  v-if="lista.length > 0 && isNew == true">
                  <v-card>
                      <v-card-text>
                         <v-row no-gutters class="subtitle-1 font-weight-bold" >
                              <v-col cols="8" sm="8" md="8" lg="8">
                                  <v-row no-gutters>
                                      <v-col cols="12" sm="7" md="7" lg="7">
                                          FECHA <span class="green--text"> {{lista[0].cifaFecha}}</span>
                                      </v-col>
                                      <v-col cols="12" sm="5" md="5" lg="5">
                                          NUM. AUTORIZACION <span class="primary--text">
                                              {{(lista[0].cifaNumeroAutorizacion)?lista[0].cifaNumeroAutorizacion:'NO TIENE'}}
                                          </span>
                                      </v-col>
                                      <v-col cols="12" sm="7" md="7" lg="7">
                                          MES <span class="primary--text">{{lista[0].mes.comaNombrelargo }}
                                          </span>
                                      </v-col>
                                  </v-row>
                              </v-col>
                              <v-col cols="4" sm="4" md="4" lg="4">
                                  <v-tooltip top v-if="(lista[0].tblCitaCita.citaTipo == 1 || lista[0].tblCitaCita.citaTipo == 0) && (lista[0].tblCitaCita.estado.comaNombrecorto=='FACTURADA' || lista[0].tblCitaCita.estado.comaNombrecorto=='ASISTIDA')">
                                      <template v-slot:activator="{ on }">
                                          <v-btn outlined small class="mx-2" color="orange" v-on="on" x-small @click="pdfEntrega(lista[0].cifaId)">
                                              <v-icon>mdi-ticket</v-icon>
                                          </v-btn>
                                      </template>
                                      <span>Generar ticked</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="lista[0].tblCitaCita.estado.comaNombrecorto=='FACTURADA' || lista[0].tblCitaCita.estado.comaNombrecorto=='ASISTIDA'">
                                      <template v-slot:activator="{ on }">
                                          <v-btn outlined small class="mx-2" color="purple" v-on="on" x-small @click="Gpdf(lista[0].cifaId)">
                                              <v-icon>mdi-file-pdf-box</v-icon>
                                          </v-btn>
                                      </template>
                                      <span>Generar pdf de factura</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="lista[0].tblCitaCita.estado.comaNombrecorto=='FACTURADA' || lista[0].tblCitaCita.estado.comaNombrecorto=='ASISTIDA' || lista[0].tblCitaCita.estado.comaNombrecorto=='ACTIVA'">
                                      <template v-slot:activator="{ on }">
                                          <v-btn outlined small class="mx-2" color="info" v-on="on" x-small  @click="editar(lista[0].cifaId)">
                                              <v-icon>create</v-icon>
                                          </v-btn>
                                      </template>
                                      <span>Editar</span>
                                  </v-tooltip>
                                  <v-tooltip top v-if="lista[0].tblCitaCita.estado.comaNombrecorto!='ASISTIDA'">
                                      <template v-slot:activator="{ on }">
                                          <v-btn outlined small class="mx-2" color="error" v-on="on" @click="eliminar(lista[0].cifaId)">
                                              <v-icon>delete</v-icon>
                                          </v-btn>
                                      </template>
                                      <span>Eliminar</span>
                                  </v-tooltip>
                              </v-col>
                          </v-row>
                          <v-divider></v-divider>
                          <v-row no-gutters>
                              <v-col cols="12" sm="12" md="3" lg="3">
                                  EPS <span class="primary--text">
                                      <template v-if="lista[0].tblConfBasedatospacientes.length > 0">
                                          {{ (lista[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemPrincipal == 1)?'PARTICULAR':lista[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemNombre }}
                                      </template>
                                      <template v-else-if="lista[0].cocoId">
                                          {{ (lista[0].tblConfContrato.tblConfPersonasempresas[0].coemPrincipal == 1)?'PARTICULAR':lista[0].tblConfContrato.tblConfPersonasempresas[0].coemNombre }}
                                      </template>
                                  </span>
                              </v-col>
                              <v-col cols="12" sm="12" md="4" lg="4">
                                  COPAGO
                                  <span class="primary--text">
                                      <v-icon style="color:#2196F3;font-size: 0.875rem;">mdi-currency-usd</v-icon>{{ lista[0].cifaCopago }}
                                  </span>
                              </v-col>
                              <v-col cols="12" sm="12" md="4" lg="5">
                                  VALE
                                  <span class="primary--text"> {{ (lista[0].cifaVale)?lista[0].cifaVale:'NO TIENE' }} </span>
                              </v-col>
                              <v-col cols="12" sm="3" md="3" lg="3">
                                  TELEMEDICINA <label class="green--text" dark v-if="lista[0].cifaTelemedicina">Si</label>
                                  <label class="red--text" dark v-else>No</label>
                              </v-col>
                              <v-col cols="12" sm="12" md="5" lg="3" v-if="lista[0].sala">
                                  SALA <span class="primary--text"> {{ lista[0].sala.comaNombrecorto}}</span>
                              </v-col>
                              <v-col cols="12" sm="12" md="5" lg="3" v-if="lista[0].cifaTipoTurno">
                                  TURNO <span class="primary--text"> {{ lista[0].cifaTipoTurno}}</span>
                              </v-col>
                          </v-row>
                      </v-card-text>
                  </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12"  v-else>
                 <v-form @submit.prevent="agregar">
                    <v-container>
                        <template v-if="item.cocoId == null">
                            <v-alert text prominent dense type="success" v-if="bd.length > 0 ">
                                El paciente se encuentra en las base de datos de <template v-for="b in bd"> {{ ','+ b.cocoNombre  }}</template>
                            </v-alert>
                            <v-alert text prominent dense type="error" v-else>
                                El paciente no se encuentra en ninguna base de datos para el mes de {{ meses[ new Date(item.citaFecha.replace(/-+/g, '/')).getMonth()]}}.
                            </v-alert>
                        </template>
                        <v-row v-if="bd.length > 0 || item.cocoId">
                            <v-col cols="12" sm="12" md="4">
                                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field outlined v-model="form.cifaFecha" label="Fecha" append-icon="event" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="ES" v-model="form.cifaFecha" @input="menu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                                <v-text-field outlined label="Numero de autorizacion" v-model="form.cifaNumeroAutorizacion"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" v-if="VERSION == 'patologia'">
                                <v-text-field outlined label="Medico remitente" v-model="form.cifaMedicoremitente"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if="item.cocoId">
                                <v-autocomplete outlined v-model="form.cocoId" :items="contratos" item-text="cocoNombre" item-value="cocoId" item-color="primary" label="Contrato" clearable>
                                    <template v-slot:selection="data">
                                        {{data.item.cocoNombre +" - "+data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}
                                    </template>
                                    <template v-slot:item="data">
                                        <template v-if="(typeof data.item !== 'object')">
                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ data.item.cocoNombre}}</v-list-item-title>
                                                <v-list-item-subtitle> {{data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" v-else>
                                <v-autocomplete outlined v-model="form.cbdpId" :items="bd" item-text="cocoNombre" item-value="cbdpId" item-color="primary" label="EPS contrada" clearable>
                                    <template v-slot:item="data">
                                        <template v-if="(typeof data.item !== 'object')">
                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-content>
                                                <v-list-item-title>{{data.item.cocoNombre}}</v-list-item-title>
                                                <v-list-item-subtitle> {{data.item.coemNombre}}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                                <v-text-field outlined label="Codigo vale" v-model="form.cifaCodigoVale"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                                <v-text-field outlined label="Copago" v-model="form.cifaCopago"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" v-if="item.citaTipo==0 && tipoturno.length > 0">
                                <v-autocomplete outlined v-model="form.cifaTipoSala" :items="tiposala" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Sala" clearable></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" v-if="item.citaTipo==0 && tipoturno.length > 0">
                                <v-autocomplete outlined required v-model="form.cifaTipoTurno" :items="tipoturno" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Turno" clearable></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if="item.citaTipo==2 || item.citaTipo==1">
                                <v-autocomplete outlined required v-model="form.cifaTipoFinalidad" :items="tipofinalidad" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de finalidad" clearable></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="4" v-if="item.citaTipo==1">
                                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field outlined v-model="form.cifaFechareclamo" label="Fecha de reclamo" prepend-icon="event" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker locale="ES" v-model="form.cifaFechareclamo" @input="menu2 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if="item.citaTipo==0">
                                <v-switch v-model="form.cifaTelemedicina" label="Telemedicina"></v-switch>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6">
                                <v-checkbox label="Requiere Consentimientos?" v-model="form.cifaConsentimiento"></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12">
                                <div v-if="form.cifaConsentimiento" style="padding: 10px">
                                    <v-layout row wrap>
                                        <v-checkbox v-model="form.cifaConProcedi" label="Consciento la Eleccion de Participantes en los Procedimientos y los Cambios en este"></v-checkbox>
                                        <v-checkbox v-model="form.cifaConDer" label="Conozco mis Derechos y Deberes como Paciente"></v-checkbox>
                                        <v-checkbox v-model="form.cifaConVidFot" label="Consciento el Uso de Fotos y Videos de mi Caso en las Redes Sociales"></v-checkbox>
                                        <v-checkbox v-model="form.cifaConProDiag" label="Consciento los procedimientos y tratamientos Diagnosticado"></v-checkbox>
                                    </v-layout>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row v-if="item.citaTipo==3 ||item.citaTipo==1">
                            <v-col cols="auto" md="12">
                                <v-row justify="space-around">
                                    <v-col cols="auto" md="12">
                                        <v-toolbar flat>
                                            <v-toolbar-title>Ordenes</v-toolbar-title>
                                            <v-divider class="mx-4" inset vertical></v-divider>
                                            <v-btn text @click="agregarOrden">Agregar nuevo</v-btn>
                                            <v-divider class="mx-4" inset vertical></v-divider>

                                            <div class="flex-grow-1"></div>
                                        </v-toolbar>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <template v-for="(items,index) in formOrdenes">
                                    <v-row :key="items.ciorId" v-if="formOrdenes.length > 0">
                                        <v-col cols="12" sm="12" md="9" v-if="items.cups">
                                            <v-autocomplete outlined required :error="$v.formOrdenes.$each[index].cocuId.$error" v-model="items.cocuId" :loading="items.cups.isLoading" :search-input.sync="items.cups.search" hide-no-data hide-selected no-filter :items="items.cups.cups" @keypress.enter="searchCup(items.cups)" item-text="cocuNombre" item-value="cocuId" item-color="primary" label="Cup" clearable></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="2" v-if="item.citaTipo==3">
                                            <v-text-field outlined required v-model="items.ciorCantidad" label="Cantidad"></v-text-field>
                                        </v-col>
                                        <v-col cols="auto" md="1" v-if="index > 0">
                                            <v-btn text icon color="error" @click="eliminarOrden(items)">
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-col>
                        </v-row>

                        <v-row v-if="item.citaTipo==4 ||item.citaTipo==3 ||item.citaTipo==2 || item.citaTipo==1 ">
                            <v-col cols="auto" md="12">
                                <v-row justify="space-around">
                                    <v-col cols="auto" md="12">
                                        <v-toolbar flat>
                                            <v-toolbar-title>Diagnosticos</v-toolbar-title>
                                            <v-divider class="mx-4" inset vertical></v-divider>
                                            <v-btn text @click="agregarDiagnostico">Agregar nuevo</v-btn>
                                            <v-divider class="mx-4" inset vertical></v-divider>

                                            <div class="flex-grow-1"></div>
                                        </v-toolbar>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <template v-for="(items,index) in formDiagnosticos">
                                    <v-row :key="index" v-if="formDiagnosticos.length > 0">
                                        <v-col cols="12" sm="12" md="12" v-if="items.tipocie10">
                                            <v-autocomplete outlined required :error="$v.formDiagnosticos.$each[index].cidiTipoCie10.$error" v-model="items.cidiTipoCie10" :loading="items.tipocie10.isLoading" :search-input.sync="items.tipocie10.search" hide-no-data hide-selected no-filter :items="items.tipocie10.tipocie10" @keypress.enter="searchCIE10(items.tipocie10)" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de CIE 10" clearable>
                                                <template v-slot:selection="data">
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.comaNombrelargo"></v-list-item-title>
                                                        <v-list-item-subtitle v-html="data.item.comaNombrecorto"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>

                                                <template v-slot:item="data">
                                                    <template v-if="(typeof data.item !== 'object')">
                                                        <v-list-item-content v-text="data.item"></v-list-item-content>
                                                    </template>
                                                    <template v-else>
                                                        <v-list-item-content>
                                                            <v-list-item-title v-html="data.item.comaNombrelargo"></v-list-item-title>
                                                            <v-list-item-subtitle v-html="data.item.comaNombrecorto"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="6">
                                            <v-autocomplete outlined :error="$v.formDiagnosticos.$each[index].cidiTipoDiagnostico.$error" required v-model="items.cidiTipoDiagnostico" :items="tipodiagnostico" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de Diagnostico" clearable></v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4">
                                            <v-autocomplete outlined :error="$v.formDiagnosticos.$each[index].cidiTipoClase.$error" required v-model="items.cidiTipoClase" :items="tipoclasedx" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de Clase" clearable></v-autocomplete>
                                        </v-col>
                                        <v-col cols="auto" md="1" v-if="index>0">
                                            <v-btn text icon color="error" @click="eliminarDiagnostico(items)">
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-col>
                            
                        </v-row>
                        <v-btn color="primary darken-1" :loading="loadingFactura" dark @click="agregar" v-if="form.cbdpId || form.cocoId">Guardar</v-btn>
                    </v-container> 
                </v-form>
              </v-col>
          </v-row>
        </v-container>
</template>

<script>
import {
  required
}
from 'vuelidate/lib/validators'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
  documentos
}
from "../../utils/documentos";
export default {
  props: {
    item: Object,
  },
  data() {
    return {
      menu: false,
      menu2: false,
      isNew:true,
      loadingFactura: false,
      dialog: false,
      VERSION: this.$cookies.get("TIPO"),
      tipo: this.$cookies.get("DEFAULT"),
      url: "cita/facturaciones/",
      user: this.$cookies.get("user"),
      token: this.$cookies.get("token"),
      atender: false,
      salario: null,
      factura:null,
      bd: [],
      meses: ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"],
      form: {
        cifaFecha: new Date().yyyymmdd(),
        cifaMedicoremitente: null,
        cifaCopago: 0,
        cifaCodigoVale: null,
        cocoId: null,
        citaTipoMes: null,
        cifaNumFactura: 0,
        citaId: null,
        cifaTipoFinalidad: null,
        cbdpId: null,
        cifaNumeroAutorizacion: null,
        cifaTelemedicina: 0,
        cifaFechareclamo: new Date().yyyymmdd(),
        cifaConsentimiento: 0,
        cifaConProcedi: 0,
        cifaConDer: 0,
        cifaConVidFot: 0,
        cifaConProDiag: 0,
      },
      lista: [],
      tipomes: [],
      formDiagnosticos: [],
      copago: 0,
      formOrdenes: [],
      tipofinalidad: [],
      tipocie10: {
        search: null,
        isLoading: false,
        tipocie10: []
      },
      tipodiagnostico: [],
      tipoordenes: [],
      tipoclasedx: [],
      tipoestado: [],
      datosdian: [],
      tipoestadocita: [],
      tipoturno: [],
      tiposala: [],
      contratos: [],
      tipoprocedimiento: [],

    };
  },
  validations: {
    formDiagnosticos: {
      $each: {

        cidiTipoCie10: {
          required,
        },

        cidiTipoDiagnostico: {
          required,
        },
        cidiTipoClase: {
          required,
        },

      }
    },
    form: {
      cifaFecha: {
        required,
      },

      cifaCopago: {
        required,
      },

      citaTipoMes: {
        required,
      },

    },
    formOrdenes: {
      $each: {
        ciorTipoOrden: {
          required,
        },
        cocuId: {
          required,
        },
      }
    },
  },
  created: async function() {
    this.lista = await this.$apiService.index(this.url + "index/" + this.item.citaId).then(data => data);
    this.form.citaId = this.item.citaId;
    this.contratos = await this.$apiService.index("sistema/contratos/index").then(data => data);
    this.tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_HISTORIAS").then(data => data);
    this.tipomes = await this.$apiService.index("sistema/maestra/index/TBL_TIPOMES").then(data => data);
    this.$apiService.index("cita/basedatospacientes/index/" + this.item.coppId + "/" + new Date().yyyymmdd()).then(data => {
      if (data.length > 0) {
        this.bd = data;
      } else {
        this.bd = [];
      }

    });
    this.$apiService.index("cita/citas/atender/" + this.item.citaId).then(data => {
      if (data > 0) {
        this.atender = true;
      }
    });

    if (this.item.citaTipo == 2) {
      this.tipofinalidad = await this.$apiService.index("sistema/maestra/index/TBL_TIPOFINALIDADPRO").then(data => data);
      // this.tipocie10 = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSCIE10").then(data => data);
      this.tipodiagnostico = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSDIAGNOSTICOS").then(data => data);
      this.tipoclasedx = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSCLASEDX").then(data => data);
      this.form.cifaFechareclamo = new Date().yyyymmdd();
      let edad = new Date().getFullYear() - new Date(this.item.tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getFullYear();
      var cie10 = await this.$apiService.index(`sistema/maestra/buscar3/TBL_TIPOSCIE10?comaNombrecorto=${(edad>17)?'Z000':'Z001'}&comaNombrelargo=${(edad>17)?'Z000':'Z001'}`).then(data => data);

      this.formDiagnosticos.push({
        tipocie10: {
          search: null,
          isLoading: false,
          tipocie10: []
        },
        cidiTipoCie10: null,

        cidiTipoDiagnosticonull: null,

        cidiTipoClase: null,

      });
      this.formDiagnosticos[0].tipocie10.tipocie10 = cie10;
      this.formDiagnosticos[0].cidiTipoCie10 = cie10[0].comaId;

      this.formDiagnosticos[0].cidiTipoDiagnostico = this.tipodiagnostico.find(e => e.comaNombrecorto == 'IMPRESIÓN_DIAGNOSTICA').comaId;

      this.formDiagnosticos[0].cidiTipoClase = this.tipoclasedx.find(e => e.comaNombrecorto == 'DX PRINCIPAL').comaId;

    }
    if (this.item.citaTipo == 1) {
      this.tipofinalidad = await this.$apiService.index("sistema/maestra/index/TBL_TIPOFINALIDADPRO").then(data => data);
    }

    if (this.item.citaTipo == 1 || this.item.citaTipo == 3 || this.item.citaTipo == 4) {
      this.tipodiagnostico = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSDIAGNOSTICOS").then(data => data);
      this.tipoclasedx = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSCLASEDX").then(data => data);
      this.tipoordenes = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSORDENES").then(data => data);
      this.tipoprocedimiento = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSPROCEDIMIENTOS").then(data => data);
    }
    this.form.cbdpId = this.item.cbdpId;
    this.form.cocoId = this.item.cocoId;
    this.form.cifaFecha = this.currentDate(new Date());
    if (this.item.citaTipo == 0) {
      this.tiposala = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSALAS").then(data => data);
      let dispo = await this.$apiService.index(`cita/disponibilidad/turnos3/${this.item.coprId}/${this.form.cifaFecha}/${this.item.citaHora}`).then(data => data);
      if (dispo.length > 0) {
        this.tipoturno = [];
        for (const turno of dispo) {
          this.tipoturno.push(turno);
        }
        this.form.cifaTipoTurno = Math.min(...this.tipoturno.map(item => item.comaId));
      } else {
        this.tipoturno = [];
      }
    }
    this.form.citaTipoMes = this.tipomes.filter(e => e.comaNombrelargo.indexOf(this.meses[new Date(this.item.citaFecha.replace(/-+/g, '/')).getMonth()]) != -1)[0].comaId;
    this.limpiar();
  },

  methods: {
    calcularCantidad(items) {
      items.cireCantidadprescrita = (parseInt(items.cireDuraciondias) * 24) / parseInt(items.cireFrecuenciahoras);
    },
    allowedDates: val => new Date(val.replace(/-+/g, '/')).getDay() != 0 && new Date(val.replace(/-+/g, '/')).getDay() != 6,
    currentDate(date) {
      const fecha = date;
      if (this.allowedDates(fecha.yyyymmdd())) {
        return fecha.yyyymmdd()
      } else {
        fecha.setDate(fecha.getDate() + 1);
        return this.currentDate(fecha);

      }
    },
    getMaestra(id, maestra) {
      if (id != null) {

        if (maestra.length > 0) {
          var ma = maestra.filter(e => e.comaId == id);
          if (ma.length > 0) {
            var m = ma[0];
            return m.comaNombrelargo;
          }
        }
      }

    },
    async searchCup(cups) {
      cups.isLoading = true;
      if (cups.search.length > 0 && cups.search.toString().replace(/ /g, "") != "") {
        this.$apiService.index("sistema/cups/buscar/" + cups.search.toUpperCase()).then(data => {
          cups.isLoading = false;
          cups.cups = data;

        });
      }

    },
    async searchCIE10(tipocie10) {
      tipocie10.isLoading = true;
      if (tipocie10.search.length > 0 && tipocie10.search.toString().replace(/ /g, "") != "") {
        tipocie10.tipocie10 = await this.$apiService.index(`sistema/maestra/buscar3/TBL_TIPOSCIE10?comaNombrecorto=${tipocie10.search}&comaNombrelargo=${tipocie10.search}`).then(data => data);
      }
      tipocie10.isLoading = false;
    },

    async agregar() {
      if (this.item.citaTipo == 1 || this.item.citaTipo == 3) {
        for (let orden of this.formOrdenes) {
          orden.ciorTipoOrden = this.tipoordenes.find(e => e.comaNombrecorto == ((this.item.citaTipo == 1) ? 'TP_ORD_LAB' : 'TP_ORD_SER')).comaId;

        }
      }
      this.$v.form.$touch();
      this.$v.formDiagnosticos.$touch();
      this.$v.formOrdenes.$touch();
      if (!this.$v.form.$error && !this.$v.formDiagnosticos.$error && !this.$v.formOrdenes.$error) {
        this.loadingFactura = true;
        try {
          if (this.form.cifaConsentimiento == 0) {
            this.form.cifaConProcedi = 0;
            this.form.cifaConDer = 0;
            this.form.cifaConVidFot = 0;
            this.form.cifaConProDiag = 0;
          }
          if (this.isNew) {
              this.form.cifaTipoEstado = this.tipoestado.filter(e => e.comaNombrelargo == 'HISTORIA ABIERTA PERMITE EDITAR')[0].comaId;
              this.form.citaTipoMes = this.tipomes.filter(e => e.comaNombrelargo.indexOf(this.meses[new Date(this.item.citaFecha.replace(/-+/g, '/')).getMonth()]) != -1)[0].comaId;
              let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
              if (data) {
                //this.$socket.emit('notificar', { title: '', mensaje: 'paciente nuevo', show: true, color: 'info' });
                this.$socket.emit('cambiarturnos', {
                  username: 'turnos',
                  version:this.user.version,
                  mensaje: 'factura'
                });
                if ((this.item.citaTipo == 1 || this.item.citaTipo == 3) && this.formOrdenes.length > 0) {

                  this.formOrdenes.forEach((d) => d.cifaId = data.cifaId);
                  this.$apiService.create("cita/asistenciales/atender/create/orden" + "?tipo=AUTORIZACION", this.formOrdenes).then(data => data);

                  this.formDiagnosticos.forEach((d) => d.cifaId = data.cifaId);
                  this.$apiService.create("cita/asistenciales/atender/create/diagnostico", this.formDiagnosticos).then(data => data);

                }
                if (this.item.citaTipo == 4 && this.formRecetarios.length > 0) {

                  this.formRecetarios.forEach((d) => d.cifaId = data.cifaId);
                  this.$apiService.create("cita/asistenciales/atender/create/recetario", this.formRecetarios).then(data => data);

                  this.formDiagnosticos.forEach((d) => d.cifaId = data.cifaId);
                  this.$apiService.create("cita/asistenciales/atender/create/diagnostico", this.formDiagnosticos).then(data => data);

                }
                if (this.item.citaTipo == 2 && this.formDiagnosticos.length > 0) {

                  this.formDiagnosticos.forEach((d) => d.cifaId = data.cifaId);
                  this.$apiService.create("cita/asistenciales/atender/create/diagnostico", this.formDiagnosticos).then(data => data);
                }

                this.lista = await this.$apiService.index(this.url + "index/" + this.item.citaId).then(data => data);
                this.$swal.fire({
                  title: "Completado!",
                  text: "Registro exitoso",
                  icon: "success",
                  confirmButtonText: "Ok"
                });
              }
          } else {
              var id = this.form.cifaId;
              let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);
              if (data) {
                if ((this.item.citaTipo == 1 || this.item.citaTipo == 3) && this.formOrdenes.length > 0) {
                  this.formOrdenes.forEach((d) => d.cifaId = data.cifaId);
                  this.$apiService.update("cita/asistenciales/atender/update/orden/" + data.cifaId + "?tipo=AUTORIZACION", this.formOrdenes).then(data => data);
                  this.formDiagnosticos.forEach((d) => d.cifaId = data.cifaId);
                  this.$apiService.update("cita/asistenciales/atender/update/diagnostico/" + data.cifaId, this.formDiagnosticos).then(data => data);

                }
                if (this.item.citaTipo == 4 && this.formRecetarios.length > 0) {
                  this.formRecetarios.forEach((d) => d.cifaId = data.cifaId);
                  this.$apiService.create("cita/asistenciales/atender/update/recetario" + data.cifaId, this.formRecetarios).then(data => data);
                  this.formDiagnosticos.forEach((d) => d.cifaId = data.cifaId);
                  this.$apiService.create("cita/asistenciales/atender/update/diagnostico" + data.cifaId, this.formDiagnosticos).then(data => data);

                }
                if (this.item.citaTipo == 2 && this.formDiagnosticos.length > 0) {
                  this.formDiagnosticos.forEach((d) => d.cifaId = data.cifaId);
                  this.$apiService.update("cita/asistenciales/atender/update/diagnostico/" + data.cifaId, this.formDiagnosticos).then(data => data);
                }
                this.lista = await this.$apiService.index(this.url + "index/" + this.item.citaId).then(data => data);
                this.isNew = true;
                this.$swal.fire({
                  title: "Completado!",
                  text: "Registro exitoso",
                  icon: "success",
                  confirmButtonText: "Ok"
                });
              }
          }
          this.$apiService.index("cita/basedatospacientes/index/" + this.item.coppId + "/" + this.item.citaFecha).then(data => {
            if (data.length > 0) {
              this.bd = data;
            } else {
              this.bd = [];
            }

          });
          this.$apiService.index("cita/citas/atender/" + this.item.citaId).then(data => {
            if (data > 0) {
              this.atender = true;
            } else {
              this.atender = false;
            }
          });
          this.close();
        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
        this.loadingFactura = false;
      }
    },
    async eliminar(id) {
      try {
          var result = await this.$swal.fire({
            title: "Estas seguro?",
            text: "No podras revertir esto!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!"
          }).then(result => result.value);
          if (result) {
            let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
            if (data) {
              this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/diagnostico");
              this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/recetario");
              this.listaCopia = this.lista = this.lista.filter(
                e => e.cifaId != id
              );

              this.$swal.fire(
                "Eliminado!",
                "ha sido eliminado con exito.",
                "success"
              );
            }

          }

          this.$apiService.index("cita/basedatospacientes/index/" + this.item.coppId + "/" + this.item.citaFecha).then(data => {
            if (data.length > 0) {
              this.bd = data;
            } else {
              this.bd = [];
            }

          });
          this.$apiService.index("cita/citas/atender/" + this.item.citaId).then(data => {
            if (data > 0) {
              this.atender = true;
            } else {
              this.atender = false;
            }
          });

      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async editar(id) {
      try {
          this.form = await this.$apiService.index(this.url + "view/" + id).then(data => data);
          this.formDiagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + this.form.cifaId + "/diagnostico").then(data => data);
          this.formDiagnosticos.forEach(async d =>
            d.tipocie10 = {
              search: null,
              isLoading: false,
              tipocie10: [this.$apiService.index("sistema/maestra/view/" + d.cidiTipoCie10).then(data => data)]
            });
          this.formOrdenes = await this.$apiService.index("cita/asistenciales/atender/index/" + this.form.cifaId + "/orden?tipo=AUTORIZACION").then(data => data);
          this.formOrdenes.forEach(orden =>
            orden.cups = {
              search: null,
              isLoading: false,
              cups: [orden.tblConfCups[0]]
            });
          this.formRecetarios = await this.$apiService.index("cita/asistenciales/atender/index/" + this.form.cifaId + "/recetario").then(data => data);
          this.isNew = false;
          if (this.item.citaTipo == 0) {
            this.tiposala = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSALAS").then(data => data);
            let dispo = await this.$apiService.index(`cita/disponibilidad/turnos3/${this.item.coprId}/${this.form.cifaFecha}/${this.item.citaHora}`).then(data => data);
            if (dispo.length > 0) {
              this.tipoturno = [];
              for (const turno of dispo) {
                this.tipoturno.push(turno);
              }
              this.form.cifaTipoTurno = Math.min(...this.tipoturno.map(item => item.comaId));
            } else {
              this.tipoturno = [];
            }
          }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async Gpdf(id) {
      try {
          var factura = await this.$apiService.index(this.url + "pdf/" + id).then(data => data);
          if (factura) {
            var datosPaciente = await this.$apiService.index("cita/citas/view/factura/" + factura.tblCitaCita.citaId).then(data => data);
            if(factura.serialId){
                this.datosdian = await this.$apiService.index(`sistema/maestra/index/TBL_DATOSDIAN/${factura.serialId}`).then(data => data);
            }
            let tipoconfig = await this.$apiService.index("sistema/maestra/index/TBL_TIPOCOFIG",this.token).then(data => data);
      
            let confOrdenes = '{}'; 
            if(tipoconfig.some(e => e.comaNombrecorto == 'TBL_CONFORDENES')){
            confOrdenes = tipoconfig.find(e => e.comaNombrecorto == 'TBL_CONFORDENES').comaValor;
            }
            datosPaciente.confOrdenes = confOrdenes;
            let empresas = await this.$apiService.index("sistema/personas/index/empresas").then(data => data);
            var tipoempresa = await this.$apiService.index("sistema/maestra/index/TBL_TIPOEMPRESA").then(data => data);
            var ips = empresas.find(e => e.tblConfPersonasempresas[0].coemPrincipal == 1 && e.tblConfPersonasempresas[0].coemTipoTipoempresa == (tipoempresa.find(a => a.comaNombrecorto == 'IPS').comaId));
            if (typeof window !== 'undefined') {
              let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,ips, this.$cookies.get("ENTIDAD"));
              let pdf = new jsPDF();
              if (this.tipo == 'EVENTO' && this.datosdian.length > 0) {
                let rep = documento.getFactura(pdf, factura, true, datosPaciente, 2, this.datosdian);
                if (parseInt(this.datosdian.find(e => e.comaNombrecorto == 'CANTIDADFACTURAS').comaValor) > 1) {
                      documento.getFactura(rep.pdf, factura, false, datosPaciente, rep.finalY + 10, this.datosdian);
                }
              } else {
                documento.getFactura(pdf, factura, false, datosPaciente, 2, this.datosdian);
              }

            }
          }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async pdfEntrega() {
      try {
          if (typeof window !== 'undefined') {
            if (this.item.estado.comaNombrecorto != 'ASISTIDA') {
              if (this.item.tblCitaFacturaciones) {
                if (this.item.tblCitaFacturaciones.length == 0) {
                  this.item.tblCitaFacturaciones.push(this.lista[0]);
                  //console.log(this.item.tblCitaFacturaciones[0]);
                }
              } else {
                this.item.tblCitaFacturaciones = [];
                this.item.tblCitaFacturaciones.push(this.lista[0]);
              }
              if (this.item.citaTipo == 1) {

                let empresas = await this.$apiService.index("sistema/personas/index/empresas").then(data => data);
                let tipoempresa = await this.$apiService.index("sistema/maestra/index/TBL_TIPOEMPRESA").then(data => data);

                let ips = empresas.find(e => e.tblConfPersonasempresas[0].coemPrincipal == 1 && e.tblConfPersonasempresas[0].coemTipoTipoempresa == (tipoempresa.find(a => a.comaNombrecorto == 'IPS').comaId));
                let x = 8;
                let pdf = new jsPDF('p', 'mm', 'a4');
                pdf.setFontSize(7);
                pdf.setTextColor(0, 0, 0);
                let ln = parseInt(ips.tblConfPersonasempresas[0].coemNombre.length / 35);
                let nombre = pdf.splitTextToSize(ips.tblConfPersonasempresas[0].coemNombre, 35);
                pdf.text(x + 20, 5, nombre, 'center');
                ln = (ln + 1) * 4.5;
                pdf.text(x + 20, ln + 3, `${ips.tipoidentificacion.comaNombrecorto} - ${ips.copeIdentificacion}`, 'center');

                pdf.setFontSize(7);
                pdf.text(x + 20, ln + 6, ips.copeDireccion, 'center');
                pdf.text(x, ln + 9, ips.copeEmail.toLowerCase());
                pdf.setDrawColor(0, 0, 0);
                pdf.line(x, ln + 10, 55, ln + 10);
                pdf.setFontSize(7);
                pdf.text(x, ln + 13, 'Identificacion ' + this.item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto + ' - ' + this.item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion);

                pdf.text(x, ln + 16, 'Apellido1:' + this.item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1);
                pdf.text(x, ln + 19, 'Apellido2:' + this.item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido2);
                pdf.text(x, ln + 22, 'Nombre1:' + this.item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1);
                pdf.text(x, ln + 25, 'Nombre2:' + this.item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre2);

                pdf.text(x, ln + 28, 'Fecha de recibido:' + this.item.tblCitaFacturaciones[0].cifaFecha);
                pdf.text(x, ln + 31, 'Fecha de entrega:' + this.item.tblCitaFacturaciones[0].cifaFechareclamo);
                pdf.text(x, ln + 34, 'Resultados: de 3:30 p.m a 5:00 p.m');
                pdf.text(x, ln + 37, 'Telefono: ' + ips.copeTelefono);
                pdf.setDrawColor(0, 0, 0);
                pdf.setFontSize(6);
                pdf.setFont("bold");
                pdf.line(x, ln + 38, 55, ln + 38);

                pdf.text(x, ln + 41, 'IPS Tools (HIS - Hospital Information System)');
                pdf.text(x, ln + 44, 'TEL: 3168775054');
                pdf.text(x, ln + 47, '® 2020 Powered by www.jasoftwaresas.com');
                pdf.line(x, ln + 48, 55, ln + 48);
                if (navigator.userAgent.indexOf('irefox') > -1) {

                  pdf.output("dataurlnewwindow");
                } else {
                  window.open(
                    URL.createObjectURL(pdf.output("blob")),
                    "_blank");
                }
              }
              if (this.item.citaTipo == 0) {

                let ultimo = await this.$apiService.index(`cita/facturaciones/ultimoturno?fecha=${(this.item.tblCitaFacturaciones.length >0)? this.item.tblCitaFacturaciones[0].cifaFecha :new Date().yyyymmdd()}&cita=${this.item.citaId}`).then(data => data);
                let empresas = await this.$apiService.index("sistema/personas/index/empresas").then(data => data);
                let tipoempresa = await this.$apiService.index("sistema/maestra/index/TBL_TIPOEMPRESA").then(data => data);
                let ips = empresas.find(e => e.tblConfPersonasempresas[0].coemPrincipal == 1 && e.tblConfPersonasempresas[0].coemTipoTipoempresa == (tipoempresa.find(a => a.comaNombrecorto == 'IPS').comaId));

                let pdf = new jsPDF('p', 'mm', 'a4');
                pdf.setFontSize(8);
                pdf.setTextColor(0, 0, 0);
                let ln = parseInt(ips.tblConfPersonasempresas[0].coemNombre.length / 35);
                let nombre = pdf.splitTextToSize(ips.tblConfPersonasempresas[0].coemNombre, 35);
                pdf.text(35, 5, nombre, 'center');
                ln = (ln + 1) * 4.5;
                pdf.text(35, ln + 4, `${ips.tipoidentificacion.comaNombrecorto} - ${ips.copeIdentificacion}`, 'center');
                pdf.text(35, ln + 8, 'TEL: ' + ips.copeTelefono, 'center');
                pdf.setFontSize(7);
                pdf.text(10, ln + 12, ips.copeDireccion);
                pdf.text(10, ln + 16, ips.copeEmail);
                pdf.setDrawColor(0, 0, 0);
                pdf.line(10, ln + 17, 65, ln + 17);
                pdf.setFontSize(18);
                pdf.setFont(undefined, "bold");
                pdf.text(35, ln + 24, 'TURNO ' + ultimo[0].cifaTipoTurno + (ultimo[0].sala ? (', ' + ultimo[0].sala) : ''),'center');
                pdf.setFontSize(9);
                pdf.setFont(undefined, "normal");
                pdf.text(10, ln + 30, 'PACIENTE:');
                pdf.text(10, ln + 34, ultimo[0].paciente_nombre1 + ' ' + ultimo[0].paciente_apellido1);
                pdf.text(10, ln + 38, this.item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.tipoidentificacion.comaNombrecorto + ' - ' + this.item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion);
                pdf.text(10, ln + 42, 'FECHA:' + ((this.item.tblCitaFacturaciones.length > 0) ? this.item.tblCitaFacturaciones[0].cifaFecha : new Date().yyyymmdd()));
                pdf.text(10, ln + 46, 'PROFESIONAL:');
                pdf.text(10, ln + 50, ultimo[0].empleado_nombre1 + ' ' + ultimo[0].empleado_apellido1);
              
                pdf.text(10, ln + 54, 'SERVICIO:');
                let ln2 = parseInt(ultimo[0].coseNombre.length / 50);

                let servicio = pdf.splitTextToSize(ultimo[0].coseNombre, 50);
                pdf.text(10, ln + 58, servicio);
                ln2 = (ln2 + 1) * 4.5;
                ln = ln2 + ln;
                pdf.setDrawColor(0, 0, 0);
                pdf.setFontSize(7);
                pdf.setFont("bold");
                pdf.line(10, ln + 62, 65, ln + 62);
                pdf.text(10, ln + 66, 'IPS Tools (HIS - Hospital Information System)');
                pdf.text(10, ln + 70, 'TEL: 3168775054');
                pdf.text(10, ln + 74, '® '+new Date().getFullYear()+' Powered by www.jasoftwaresas.com');
                pdf.line(10, ln + 76, 65, ln + 76);
                if (navigator.userAgent.indexOf('irefox') > -1) {

                  pdf.output("dataurlnewwindow");
                } else {
                  window.open(
                    URL.createObjectURL(pdf.output("blob")),
                    "_blank");
                }
              }
            } else {
              this.$swal.fire({
                title: "Cita atendida!",
                text: "ya esta cita fue atendida",
                icon: "warning"
              });
            }

          }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    agregarOrden() {
      this.formOrdenes.push({
        ciorTipoOrden: null,
        ciorObservacion: null,
        ciorTipoProcedimiento: (this.tipoprocedimiento.length > 0) ? this.tipoprocedimiento.find(e => e.comaNombrecorto == 'AUTORIZACION').comaId : null,
        cifaId: null,
        cocuId: null,
        cups: {
          search: null,
          isLoading: false,
          cups: []
        },
      });

    },
    eliminarOrden(item) {
      this.formOrdenes = this.formOrdenes.filter(
        e =>
        this.formOrdenes.indexOf(e) !=
        this.formOrdenes.indexOf(item)
      );
    },
    agregarDiagnostico() {
      this.formDiagnosticos.push({

        cidiTipoCie10: null,

        cidiTipoDiagnostico: null,

        cidiTipoClase: null,

        cifaId: null,
        tipocie10: {
          search: null,
          isLoading: false,
          tipocie10: []
        }

      });
    },
    eliminarDiagnostico(item) {
      this.formDiagnosticos = this.formDiagnosticos.filter(
        e =>
        this.formDiagnosticos.indexOf(e) !=
        this.formDiagnosticos.indexOf(item)
      );
    },
    async limpiar() {
      this.form = {

        cifaFecha: new Date().yyyymmdd(),
        cifaFechareclamo: new Date().yyyymmdd(),
        cifaCopago: 0,
        cifaCodigoVale: null,
        cifaMedicoremitente: null,
        cifaNumFactura: 0,
        citaTipoMes: this.tipomes.filter(e => e.comaNombrelargo.indexOf(this.meses[new Date(this.item.citaFecha.replace(/-+/g, '/')).getMonth()]) != -1)[0].comaId,
        cifaTipoFinalidad: null,
        citaId: this.item.citaId,
        cocoId: this.item.cocoId,
        cbdpId: this.item.cbdpId,
        cifaNumeroAutorizacion: null,
        cifaTelemedicina: 0,
        serialId: null,
        cifaConsentimiento: 0,
        cifaConProcedi: 0,
        cifaConDer: 0,
        cifaConVidFot: 0,
        cifaConProDiag: 0,
      };
      /*if (this.item.cocoId) {
          let serial = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSERIALES").then(data => data);
          serial = serial.filter(e => new Date(e.comaNombrelargo.split(';')[0].replace(/-+/g, '/')).getTime() <= new Date().getTime() && new Date(e.comaNombrelargo.split(';')[1].replace(/-+/g, '/')).getTime() >= new Date().getTime());

          serial.sort((a, b) => parseInt(a.comaValor) - parseInt(b.comaValor));
          this.form.serialId = serial[0].comaId;
          this.datosdian = await this.$apiService.index(`sistema/maestra/index/TBL_DATOSDIAN/${serial[0].comaId}`).then(data => data);
          console.log(this.datosdian);
          this.form.cifaNumFactura = this.datosdian.find(e => e.comaNombrecorto == 'PREFIJO_SERIE').comaValor + (parseInt(this.datosdian.find(e => e.comaNombrecorto == 'NUMERO_ACTUAL').comaValor) + 1);

      }*/
      if (this.item.citaTipo == 1 || this.item.citaTipo == 3 || this.item.citaTipo == 4) {
        this.formDiagnosticos = [{

          cidiTipoCie10: null,

          cidiTipoDiagnostico: null,

          cidiTipoClase: null,

          cifaId: null,
          tipocie10: {
            search: null,
            isLoading: false,
            tipocie10: []
          }

        }];
        this.formOrdenes = [{
          ciorTipoOrden: null,
          ciorTipoProcedimiento: (this.tipoprocedimiento.length > 0) ? this.tipoprocedimiento.find(e => e.comaNombrecorto == 'AUTORIZACION').comaId : null,
          ciorObservacion: null,
          cifaId: null,
          cocuId: null,
          ciorCantidad: 0,
          cups: {
            search: null,
            isLoading: false,
            cups: []
          },
        }];
      }
    },
    close() {
      this.isNew = true;
      this.dialog = false;
      this.limpiar();
    }
  }
};

</script>
